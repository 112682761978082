.scanPage .sidebar {
  border-top: 1px solid #ccc;
  border-left: none;
}

@media screen and (min-width: 768px) {
  .scanPage .sidebar {
    max-width: 360px;
    flex-basis: 360px;
    border-top: none;
    border-left: 1px solid #ccc;
  }
}