html {
  height: 100%;
}

body {
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}