.messageList {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.title {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.list {
  margin-top: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}