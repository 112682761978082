.message {
  /* padding: .25rem 0.5rem; */
  margin-bottom: 0.5rem;
  /* border: 1px solid transparent; */
  /* border-radius: .25rem; */
  color: #818182;
  /* background-color: #fefefe; */
  /* border-color: #fdfdfe; */
}

.success {
  color: #155724;
  /* background-color: #d4edda; */
  /* border-color: #c3e6cb; */
}

.error {
  color: #721c24;
  /* background-color: #f8d7da; */
  /* border-color: #f5c6cb; */
}